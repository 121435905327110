import React  from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Handshake, Pix, Receipt} from '@mui/icons-material';
//import Axios from 'axios';
import RiwayatScroll from './RiwayatScroll';
//const queryParams = new URLSearchParams(window.location.search);
//const id = queryParams.get('sess_id');
const TransaksiTabs = ({id_sess}) => {
    const [value, setValue] = React.useState('2');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return <Box sx={{ width: '100%', typography: 'body1', paddingY: 2 }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="Daftar Nota Pembelian" centered variant="fullWidth">
            <Tab label="Tagihan" value="1" icon={<Receipt />} iconPosition="start" />
            <Tab label="Proses" value="2" icon={<Pix />} iconPosition="start" />
            <Tab label="Lunas" value="3" icon={<Handshake />} iconPosition="start" />
          </TabList>
        </Box>
        <TabPanel value="1"><RiwayatScroll mode={1} id={id_sess} /></TabPanel>
        <TabPanel value="2"><RiwayatScroll mode={2} id={id_sess}/></TabPanel>
        <TabPanel value="3"><RiwayatScroll mode={3} id={id_sess}/></TabPanel>
      </TabContext>
    </Box>;
}

export default TransaksiTabs;