import React, {useEffect, useState}  from 'react';
import { Grid, Paper, Typography, Box, Button, Stepper, Step, StepLabel, StepContent } from '@mui/material';
import logo from '../images/sx1.png';
import QRCode from 'qrcode.react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { ZoomIn} from '@mui/icons-material';
import Axios from 'axios';
import {Link,useLocation} from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';

const Etrack = () => {
  const API_URL = 'https://enota.suryamotor.co.id/apicall/';
  const [data, setData] = useState(null);
  const [armada, setArmada] = useState("");
  const [isLoading , setIsLoqading] = useState(true);
  const [isLoadingArmada , setIsLoadingArmada] = useState(true);
  let queryParams = new URLSearchParams(useLocation().search);
  let id = queryParams.get('awb_id');
  
  const getArmada = async ()=> {
    // eslint-disable-next-line
    setIsLoadingArmada(true);
      const user = {
        sess_id: id,
        code: "6D8C6B3F"
      };
      
      Axios.post(API_URL,user,{headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }}).then(response => response.data).then((data) => {
          setArmada(data.header.armada);
          setIsLoadingArmada(false);
        });
        // eslint-disable-next-line
  }
  useEffect(() => {
    const getData = async () => {
      const user = {
        sess_id: id,
        code: "6D8C6B1A"
      };

      Axios.post(API_URL, user, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }).then(response => response.data).then((data) => {
        setData(data);
        setIsLoqading(false);
        setArmada(data.header.armada);
        setIsLoadingArmada(false);
      });
    }

    getData();

  }, [id]);

  if (!isLoading && !data.res) {
    window.location.href = "https://www.selismolis.com/";
    return;
  }
  return (
    <Grid style={{ width: '100%' }} paddingY={3}>
      <Grid item xs={6}>
        <Paper elevation={3} paddingX={2}>
        <img src={logo} alt="" className='img2' />
        <Typography variant='h5' align="center">Lacak Paket</Typography> 
        {!isLoading ? (<>
          <Grid container justifyContent="center">
          <QRCode value={data.header.id}/>
        </Grid>
        <Grid container justifyContent="center">
        <Typography variant="body1">{data.header.id}</Typography>
        </Grid>
        </>):(<><Grid container justifyContent="center"><Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width="40%" height={300} animation="wave" /></Grid></>)}
        
          <Box p={2} paddingX={3}>
            <Typography variant="body2">
              <table>
                <tr>
                  <td>Pengirim</td>
                  <td>:</td>
                  <td>{
                    !isLoading ? data.header.sender : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }</td>
                </tr>
                <tr>
                  <td valign='top'>Tujuan</td>
                  <td valign='top'>:</td>
                  <td valign='top'>{
                    !isLoading ? data.header.destination : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )}</td>
                </tr>
                <tr>
                  <td width='70'>Total Koli</td>
                  <td>:</td>
                  <td>{
                    !isLoading ? data.header.size : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )}</td>
                </tr>
                <tr>
                  <td width='70'>Status</td>
                  <td>:</td>
                  <td>{
                    !isLoading ? data.header.status_text : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )}</td>
                </tr>
              </table>
            </Typography>
            
          </Box>
          {!isLoading ? (<>
            <Grid container spacing={2} paddingX={2} paddingY={2} alignItems="center" justifyContent="justify">
            
            {data.header.faktur.map((item, index) => (
              <Grid item xs={6} key={index} justifyContent="center">
                <Button variant="outlined" size='small' startIcon={<ZoomIn />} component={Link} to={`/ereciept?id=${item.faktur_session}`}>{item.faktur_id}</Button>
              </Grid>
            ))}
          </Grid>
          
          </> ):(<><Box p={2} paddingX={3}><Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width="95%" height={150} animation="wave" /></Box></>)}
          
        </Paper>
      </Grid>
      {isLoading ? (<>
        <Box p={2} paddingX={3}><Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width="95%" height={150} animation="wave" /></Box>
      </>) : (<></>)}
      {!isLoading && data.header.status == 2 ? (<>
        <Grid  paddingY={3} item xs={6}>
      <Paper elevation={2}>
            <Box paddingY={2} paddingX={2}>
              <Typography variant="body2">Posisi Armada: {isLoadingArmada ? (<><Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" /></>): armada}</Typography>
                <Button variant="contained" startIcon={<AutorenewIcon/>} onClick={getArmada}>Cari </Button>
              </Box>
            </Paper>
      </Grid>
      </>):(<></>)}
      
      <Grid  paddingY={2} item xs={6}>
        <Paper elevation={3}>
          <Box p={2}>
            <Typography variant="h6">Riwayat</Typography>
            {isLoading ? (<><Box p={2} paddingX={3}><Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width="95%" height={150} animation="wave" /></Box></>) : (<>
              <Box>
              <Stepper orientation="vertical">
                {data.log.map((item, index) => (
                  <Step key={index} completed={index + 1 == data.header.active ? "":"Completed"} active={data.header.active}>
                    <StepLabel><Typography variant='caption'>{item.date}</Typography></StepLabel>
                    <StepContent><Typography variant='body2'>{item.description}</Typography></StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
            </>)}      
            
          </Box>
        </Paper>
      </Grid>
    </Grid>
    
  );
};

export default Etrack;
