import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import React from "react";
import LinearProgress from '@mui/material/LinearProgress';

const Loader = ()=> {
    return <Box paddingX={1} paddingY={2}>
        <LinearProgress color="secondary" />
        <Typography align="center">
            Memuat data
        </Typography>
    </Box>
   

}

export default Loader;