import React, {useEffect, useState}  from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import logo from '../images/SM_Landscape.png';
import {AccountBox, WhatsApp,ShoppingCart, AccountBalanceWallet, PriceCheck, CurrencyExchange,Place, PointOfSale, AccountBalance,ZoomIn} from '@mui/icons-material';
import Skeleton from '@mui/material/Skeleton';
import Axios from 'axios';
import TransaksiTabs from './TransaksiTabs';
import {Link,useLocation} from "react-router-dom";
import Button from '@mui/material/Button';
//const API_URL = 'https://telebna.powerlinecod.com/ftcb/order_list.php';

const PelangganDetails = ()=>{
    const API_URL = 'https://enota.suryamotor.co.id/apicall/';
    let queryParams = new URLSearchParams(useLocation().search);
    let id = queryParams.get('sess_id');
    const [data, setData] = useState(null);
    const [isLoading , setIsLoqading] = useState(false);

    const getData = async ()=> {
      // eslint-disable-next-line
        const user = {
          sess_id: id,
          code: "0FF910BC"
        };
        
        Axios.post(API_URL,user,{headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          }}).then(response => response.data).then((data) => {
            setData(data);
            setIsLoqading(true);
          });
          // eslint-disable-next-line
    }
    useEffect(() => {
        // eslint-disable-next-line
        getData();
        // eslint-disable-next-line
    },[]);
    if(isLoading && !data.res){
        window.location.href="https://www.selismolis.com/";
        return;
    }
    return <><Grid item xs={5} key={0}>
        <Paper elevation={6} sx={{paddingY: 2}} variant='elevation'>
            <img src={logo} alt="" className='img' />
        <Box paddingX={1}>
            <Typography component="h6" variant="h6">
                {
                    isLoading ? data.nama : (
                        <Skeleton variant="rounded" animation="wave" />
                    )
                }
            </Typography>

            <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
                <AccountBox />
                <Typography sx={{paddingX: 1}} variant="button" component="p">
                {
                    isLoading ? data.cid : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }
                </Typography>
            </Box>
            <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
                <Place />
                <Typography sx={{paddingX: 1}} variant="subtitle2" component="p">
                {
                    isLoading ? data.alamat : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }
                </Typography>
            </Box>
            <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
                <WhatsApp />
                <Typography sx={{paddingX: 1}} variant="button" component="p">
                {
                    isLoading ? data.hp1 : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }
                </Typography>
            </Box>
            <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
                 <WhatsApp />
                <Typography sx={{paddingX: 1}} variant="button" component="p">
                {
                    isLoading ? data.hp2 : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }
                </Typography>
            </Box>
            <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
                <AccountBalanceWallet />
                 <Typography sx={{paddingX: 1}} variant="subtitle2" component="p">
                 {
                    isLoading ? data.limit : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }
                 </Typography>
            </Box>
            <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
                 <ShoppingCart />
                 <Typography sx={{paddingX: 1}} variant="subtitle2" component="p">
                 {
                    isLoading ? data.hutang : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }
                 </Typography>
            </Box>
            <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
                 <PointOfSale />
                 <Typography sx={{paddingX: 1}} variant="subtitle2" component="p">
                 {
                    isLoading ? data.tempo : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }
                 </Typography>
            </Box>
            <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
               <PriceCheck />
                 <Typography sx={{paddingX: 1}} variant="subtitle2" component="p">
                 {
                    isLoading ? data.bayar : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }
                 </Typography>
            </Box>
            <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
                <CurrencyExchange />
                 <Typography sx={{paddingX: 1}} variant="subtitle2" component="p">
                 {
                    isLoading ? data.sisa : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }
                 </Typography>
            </Box>
            <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
                <AccountBalance />
                
                 {
                    isLoading ?(<>
                        <Typography component="p" variant='subtitle2' sx={{paddingX: 0.5}}>
                   {data.saldo}
                </Typography></>
                    ) : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }
                 
            </Box>
            <Box
            sx={{
                display: "flex",
                alignItems: "center"
            }}
            >
                              
                 {
                    isLoading ?(<>
                       <Button sx={{paddingY: 0.5, textTransform: "none"}} component={Link} to={`/emutasi?cust_id=${data.cust_id}`} size="small" variant="outlined" startIcon={<ZoomIn /> }>
                Cek Mutasi
                </Button></>
                    ) : (
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width={140} animation="wave" />
                    )
                }
                 
            </Box>
        </Box>
        </Paper>
        
    </Grid>
    {
        isLoading ? (<TransaksiTabs id_sess={id} />) : (
                        <Skeleton ariant="rectangular" width='100%' height={200} animation="wave" />
                    )
                }
    </>
    
}
export default PelangganDetails;