/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState}  from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import logo from '../images/SM_Landscape.png';
import Skeleton from '@mui/material/Skeleton';
import Axios from 'axios';
import NotaHeader from './NotaHeader';
import NotaDetail from './NotaDetail';
import { useLocation } from 'react-router-dom';

const API_URL = 'https://enota.suryamotor.co.id/apicall/';
const Enota = ()=> {
    const [data, setData] = useState(null);
    const [isLoading , setIsLoqading] = useState(false);
    let queryParams = new URLSearchParams(useLocation().search);
    let id = queryParams.get('id');
    
    const getData = async ()=> {
        
        const user = {
          sess_id: id,
          code: "BC374FAD8"
        };
        
        Axios.post(API_URL,user,{headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          }}).then(response => response.data).then((data) => {
            setData(data);
            setIsLoqading(true);
          });
    }

    useEffect(() => {
        getData();
        
    },[]);
    if(isLoading && !data.res){
        window.location.href="https://www.selismolis.com/";
        return;
    }
    return <Grid>
        <Paper elevation={3} sx={{paddingY: 2}} variant='elevation'>
        <img src={logo} alt="" className='img' />
            {
                isLoading ? (
                <><NotaHeader header={data.header} /><NotaDetail data={data.detail} header={data.header} /></>
                ):(
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Skeleton ariant="rectangular" sx={{ fontSize: '1.5rem'}} width="90%" height={300} animation="wave" />
                        </Box>
                     )
            }
        </Paper>
    </Grid>
}

export default Enota;