import React, {useEffect, useState}  from 'react';
import { Box, Button, Grid, Paper, TextField, Typography, Skeleton } from '@mui/material';
import { Rating } from '@mui/material';
import axios from 'axios';
import logo from '../images/SM_Landscape.png';
import SendIcon from '@mui/icons-material/Send';
import {Link,useLocation} from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Erating() {
  const [teknisiRating, setTeknisiRating] = React.useState(5);
  const [csRating, setCsRating] = React.useState(5);
  const [kritik, setKritik] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [ukey, SetUkey] = React.useState('');
  const [ucode, SetUCode] = React.useState('');
  const API_URL = 'https://enota.suryamotor.co.id/apicall/';
  let queryParams = new URLSearchParams(useLocation().search);
  let id = queryParams.get('transaction');

  const [data, setData] = useState(null);

  const getData = async ()=> {
    // eslint-disable-next-line
      const user = {
        sess_id: id,
        code: "4B90F1C"
      };
      
      axios.post(API_URL,user,{headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }}).then(response => response.data).then((data) => {
          setData(data);
          setIsLoading(true);
          SetUkey(data.data.ukey);
          SetUCode(data.data.code);
        });
        // eslint-disable-next-line
  }

  const handleSubmit = () => {
    setIsLoading(false);
    axios.post(API_URL, {
      sess_id: ukey,
      code: ucode,
      r_teknisi: teknisiRating,
      r_cs: csRating,
      saran: kritik
    },{headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }}).then(response => response.data).then((data) => {
          setData(data);
          setIsLoading(true);
        }).catch(error => {
      console.log(error);
      setIsLoading(true);
    });
  };

  useEffect(() => {
  setIsLoading(false);
    // eslint-disable-next-line
    getData();
    // eslint-disable-next-line
},[]);
if(isLoading && !data.res){
  window.location.href="https://www.selismolis.com/";
  return;
}
if(!isLoading){
  return ((<><Skeleton variant="rectangular" width="100%" height={650} /></>));
}else{
  if(!data.data.finish){
    return (<>
      <Grid item sx={{ width: '100%', maxWidth: 500 }}>
        <Paper elevation={3} variant="elevation" sx={{ py: 2 }}>
          <img src={logo} alt="" className='img' />
          <Box sx={{ px: 1 }}>
            <br />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h6">Berikan Penilaian Anda</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'left' }}>
              <Typography variant="body2">
                <p>Terima kasih kak {data.data.nama} telah menggunakan layanan di Surya Motor</p>
                <p>Kami ingin memastikan Anda puas dengan layanan dan produk kami.</p>
                <p>Bagaimana penilaian anda terhadap layanan dan kinerja kami?</p>
              </Typography>
            </Box>
            <br />
            <Typography variant="body2">Kinerja Teknisi</Typography>
            <Rating
              name="teknisi-rating"
              size='large'
              precision={0.5}
              value={teknisiRating}
              onChange={(event, newValue) => {
                setTeknisiRating(newValue);
              }}
            />
            <Typography variant="body2">Layanan CS</Typography>
            <Rating
              name="cs-rating"
              size='large'
              precision={0.5}
              value={csRating}
              onChange={(event, newValue) => {
                setCsRating(newValue);
              }}
            />
            <br />
            <Typography variant="body2">
              <p>Jika ada pertanyaan atau masalah, jangan ragu untuk menghubungi kami. Kami selalu terbuka terhadap kritik dan saran dari pelanggan.</p>
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              margin="normal"
              variant="outlined"
              label="Kritik/Saran/Masukkan Anda"
              value={kritik}
              onChange={(e) => setKritik(e.target.value)}
            />
           <Button variant="contained" color="primary" onClick={handleSubmit} startIcon={<SendIcon />}>
               Kirim
            </Button>
            
          </Box>
        </Paper>
      </Grid>
      </>
    );
  }else{
    return (<>
      <Grid item sx={{ width: '100%', maxWidth: 500 }}>
        <Paper elevation={3} variant="elevation" sx={{ py: 2 }}>
          <img src={logo} alt="" className='img' />
          <Box sx={{ px: 1 }}>
            <br />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h6">Terima Kasih</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'left' }}>
              <Typography variant="body2">
                <p className='paragraf'>Terima kasih kak {data.data.nama} telah memberikan penilaian atas layanan Surya Motor.</p>
                <p className='paragraf'>Penilaian anda sangat berkontribusi besar demi menjaga komitmen Surya Motor untuk terus memberikan pelayanan yang terbaik kepada anda dan pelanggan.</p>
                <p className='paragraf'>Terima kasih atas penilaian anda terhadap layanan dan kinerja kami.</p>
                <p className='paragraf'>Apabila anda menemui kendala,silahkan kontak kami melalui tombol dibawah</p><br />
                <Button variant="contained" color="primary" component={Link} to={data.data.cs_contact} startIcon={<WhatsAppIcon />} sx={{ display: 'flex', justifyContent: 'center' }}>
                  Hubungi Kami
                </Button>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
      </>
    );
  }
  }
}

export default Erating;