import React from "react";
import Box from '@mui/material/Box';
import  Typography from '@mui/material/Typography';
import QRCode from 'qrcode.react';
import Grid from '@mui/material/Grid';

const NotaHeader = ({header})=> {
    return (
        <>
            <Box paddingX={1}>
                <Grid container justifyContent="center">
                    <QRCode value={header.faktur_id}/>
                </Grid>
                <Typography component="h6" align='center' variant='h6'>
                    {header.faktur_id}
                </Typography>
            </Box>
            <Box paddingX={2} paddingY={3} sx={{
        display: "flex",
        alignItems: "center"
    }}
    >
            <table>
                <thead></thead>
                <tbody>
                    <tr>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">Jenis</Typography></td>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">{header.jenis}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">Tanggal</Typography></td>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">{header.tanggal}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">{header.strtempo}</Typography></td>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">{header.jttempo}</Typography></td>
                    </tr>
                    <tr>
                        <td valign='top'><Typography sx={{ paddingX: 1 }} variant="body2" component="p">Pelanggan</Typography></td>
                        <td valign='top'><Typography sx={{ paddingX: 1 }} variant="body2" component="p">:</Typography></td>
                        <td valign='top'><Typography sx={{ paddingX: 1 }} variant="body2" component="p">{header.pelanggan} <br />
                            {header.alamat}</Typography></td>
                    </tr>
                    {header.sales.length>2 ?(<>
                        <tr>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">Sales</Typography></td>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">{header.sales}</Typography></td>
                    </tr>
                    </>):(<></>)}
                    
                    {header.kasir.length > 2 ?(<>
                        <tr>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">Kasir</Typography></td>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{ paddingX: 1 }} variant="body2" component="p">{header.kasir}</Typography></td>
                    </tr>
                    </>):(<></>)}
                    
                </tbody>

            </table>
        </Box></>
    );
}

export default NotaHeader;