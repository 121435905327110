import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
const ArrAlign = ['left','center','right'];
const NotaDetailSerial = ({item_serial})=> {
    if(item_serial.length){
        //tampilkan memo imei jika ada
        return(<>
        <tr key='823523'>
            <td colSpan={2}>
            <Box sx={{ flexGrow: 1, paddingX: 3 }}>
            <Grid  container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {
                    item_serial.map((imei,key)=> {
                        return(<>
                            <Grid item xs={2} sm={4} md={4} key={key}>
                                <Paper elevation={0} align={ArrAlign[key % 3]}>
                                    <Typography sx={{paddingX: 1}} variant="body2" component="td">{imei}</Typography>
                                </Paper>
                            </Grid>
                        </>);
                    })
                }
            </Grid>
            </Box>
            </td>
        </tr>
        </>);
    }else{
        return <></>
    }
}

export default NotaDetailSerial;