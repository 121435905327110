import * as React from 'react';
import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ZoomIn from '@mui/icons-material/ZoomIn';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { subDays } from 'date-fns';
import { enGB } from 'date-fns/locale';
import Axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loader from './Loader';
import Loaded from './Loaded';
import InfiniteScroll from 'react-infinite-scroll-component';
import MutasiDetail from './MutasiDetail';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const API_URL = 'https://enota.suryamotor.co.id/apicall/';
let url_session ='';
function MyAppBar() {
    const color = "white";
    const theme = createTheme({
        palette: {
            mode: 'dark',
          },
        components: {
            MuiIconButton: {
                styleOverrides: {
                    sizeMedium: {
                        color
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        color, fontSize: '1rem',
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        width: '0.5rem', padding: '5px',

                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color, fontSize: '0.8rem', height: '0.5rem'
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color, fontSize: '0.9rem'
                    }
                }
            }
        }
    });
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const queryParams = new URLSearchParams(useLocation().search);
    const id = queryParams.get('cust_id');
    const [hasMore, setHasMore] = useState(true);

    const handleStartDateChange = (date) => {
        
        setPage(1); // Reset the page to 1
        setHasMore(true); // Reset the hasMore to true
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        
        setPage(1); // Reset the page to 1
        setHasMore(true); // Reset the hasMore to true
        setEndDate(date);
    };

    const handleSubmit = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setData([]); // Reset the data
        setPage(1); // Reset the page to 1
        setHasMore(true); // Reset the hasMore to true
        fetchData(); // Call the fetchData function
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await Axios.post(API_URL, {
                startDate: startDate.toISOString().split('T')[0],
                endDate: endDate.toISOString().split('T')[0],
                sess_id: id,
                code: "3C7B1DAE8",
                page: page
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                 }   
             });
            setData((prevData) => [...prevData, ...response.data.records]);
            setPage((prevPage) => prevPage + 1);
            setHasMore(response.data.more);
            url_session = response.data.url_session;
            if(response.data.res === false){
                window.location.href="https://www.selismolis.com/";
                return; 
            }
            if (response.data.records.length === 0 || response.data.more === false) {
                setHasMore(false);
                setPage(1);
            }
        } catch (error) {
                if(!data.res) {
                    window.location.href="https://www.selismolis.com/";
                    return;
                }else{
                    console.error(error);
                }
          
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<>
        <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="back" component={Link}  to={`/?sess_id=${url_session}`}>
                        <ArrowBackIcon />
                </IconButton>
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                    Mutasi
                </Typography>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                        <MobileDatePicker
                            label="Mulai"
                            value={startDate}
                            maxDate={new Date()}
                            minDate={subDays(new Date(), 90)}
                            onChange={handleStartDateChange}
                            TextField={(params) => <TextField {...params} sx={{ label: 'white' }} />}
                            sx={{ width: 150 }}
                        />&nbsp;&nbsp;
                        <MobileDatePicker
                            label="Sampai"
                            value={endDate}
                            maxDate={new Date()}
                            minDate={subDays(new Date(), 90)}
                            onChange={handleEndDateChange}
                            TextField={(params) => <TextField {...params} sx={{ label: 'white' }} />}
                            sx={{ width: 150 }}
                        />
                    </LocalizationProvider>&nbsp;&nbsp;

                    <Button color="inherit" sx={{ textTransform: "none", marginLeft: 'auto' }} onClick={handleSubmit} size="small" variant="outlined" >
                        <ZoomIn />
                    </Button>
                </ThemeProvider>
            </Toolbar>
            
        </AppBar>
        {/* Infinite Scroll */}
        <div>
        <InfiniteScroll
            dataLength={data.length}
            next={fetchData}
            hasMore={hasMore}
            >
             <MutasiDetail data={data}/>
             {loading && <Loader />}
        </InfiniteScroll>
        {!hasMore && <Loaded />}
       
    </div></>

    );
}

export default MyAppBar;