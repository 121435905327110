import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowUpwardIcon from '@mui/icons-material/VerticalAlignTop';

function ScrollToTopButton() {
    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div align="center">
            <IconButton onClick={handleClick}>
                <ArrowUpwardIcon />
                <Typography variant="body1">Semua data sudah ditampilkan</Typography>
                <ArrowUpwardIcon />
            </IconButton>
            
        </div>
    );
}

export default ScrollToTopButton;