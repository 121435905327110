import Paper from '@mui/material/Paper';
import React from "react";
import Box from '@mui/material/Box';
import  Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ZoomIn} from '@mui/icons-material';
import {Link} from "react-router-dom";
import Button from '@mui/material/Button';
import XpdcStatus from './XpdcStatus';
const TransaksiPapers = ({data}) => {
    return(<>
        {data.map((rs,k)=> {
            return <Grid sx={{paddingY: 1, paddingX:1}} key={k+1}>
            <Paper elevation={4} sx={{paddingY: 2, paddingX: 1}} variant='elevation'>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                paddingX: 1
            }}>
                         
                <Button sx={{paddingY: 0.5}} component={Link} to={`/ereciept?id=${rs.detail_session}`} size="small" variant="outlined" startIcon={<ZoomIn />}>
                <Typography component="p" variant='button' sx={{paddingX: 0.5}}>
                   {rs.faktur_id}
                </Typography>
                </Button>
            </Box>
       
                <Box
                sx={{
                    display: "flex",
                    alignItems: "center"
                }}
                >    
                <table>
                <thead></thead> 
                <tbody>
                    <tr>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">Jenis</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">{rs.type}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">Tanggal</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">{rs.date}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">Jatuh Tempo</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">{rs.duedate}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">Total Item</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">{rs.item}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">Total Unit</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">{rs.unit}</Typography></td>
                    </tr>
                    
                    <tr>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">Grand Total</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">{rs.bruto}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">Total Potongan</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p" className='potongan'>({rs.potongan})</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">Total</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">{rs.total}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">Pembayaran</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">{rs.payment}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">Sisa</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">{rs.gap}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">Kurir</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">:</Typography></td>
                        <td><Typography sx={{paddingX: 1}} variant="body2" component="p">{rs.xpdc}</Typography></td>
                    </tr>
                </tbody>
    
                </table>
                    
                </Box>
                <XpdcStatus status={rs.xpdc_status} />
            </Paper>
    
        </Grid>
        })}
    </>);
    
}

export default TransaksiPapers;