import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import NotaDetailJudul from "./NotaDetailJudul";
import NotaDetailItem from './NotaDetilItem';
import NotaDetailPotongan from "./NotaDetailPotongan";
import NotaDetailGaransi from "./NotaDetailGaransi";
import NotaDetailSerial from "./NotaDetailSerial";
import {Reply,FileCopy} from '@mui/icons-material';
import XpdcStatus from './XpdcStatus';
import {Link} from "react-router-dom";
import Button from '@mui/material/Button';
import { toast } from 'react-toastify'

let jns_id = -1; 

const handleCopyToClipboardBRI = () => {
    const valueToCopy = '000401002451306';
    //navigator.clipboard.writeText(valueToCopy);
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = valueToCopy;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    toast('No Rekening BRI sukses tersalin', {
        autoClose: 2000
      });
  };

  const handleCopyToClipboardBCA = () => {
    const valueToCopy = '3571244444';
    //navigator.clipboard.writeText(valueToCopy);
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = valueToCopy;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    toast('No Rekening BCA sukses tersalin', {
        autoClose: 2000
      });
  };

const NotaDetail = ({data, header}) => {
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    return <><Divider>
        <Typography sx={{ paddingX: 1 }} variant="button" component="p">Daftar Barang</Typography>

    </Divider>
    <Box paddingX={1} paddingY={2} sx={{
        display: "flex",
        alignItems: "center"
    }}
    >
        <table width='100%' key='2234'>
            <tbody key='23453'>  
                {data.map((rs,k)=> {
                    if(jns_id < 0 || jns_id !== rs.jenis_id){
                        jns_id = rs.jenis_id;
                        return (<>
                            <tr key={k+2343}>
                                <td colSpan={2} className="pindahan">
                                    <NotaDetailJudul judul={rs.jenis_nama} key={k} /> 
                                </td>
                                </tr>
                            <NotaDetailItem 
                            k={k+134324}
                            merk_nama={rs.merk_nama}
                            merk_tipe={rs.merk_tipe}
                            item_nama={rs.item_nama}
                            item_unit={rs.item_unit}
                            item_harga={rs.item_harga}
                            item_total={rs.item_total}
                            />
                           
                            <NotaDetailPotongan 
                            strpotongan={rs.item_str_potongan}
                            item_potongan={rs.item_potongan}
                            item_unit={rs.item_unit}
                            item_totpot={rs.item_totpot}
                            />

                            <NotaDetailGaransi item_garansi={rs.item_garansi}/>
                            <NotaDetailSerial item_serial={rs.item_serial} />
                        </>)
                    }else{
                        return(<>
                        <NotaDetailItem 
                            k={k+1}
                            merk_nama={rs.merk_nama}
                            merk_tipe={rs.merk_tipe}
                            item_nama={rs.item_nama}
                            item_unit={rs.item_unit}
                            item_harga={rs.item_harga}
                            item_total={rs.item_total}
                            />
                            
                            <NotaDetailPotongan 
                            strpotongan={rs.item_str_potongan}
                            item_potongan={rs.item_potongan}
                            item_unit={rs.item_unit}
                            item_totpot={rs.item_totpot}
                            />

                            <NotaDetailGaransi item_garansi={rs.item_garansi}/>
                            <NotaDetailSerial item_serial={rs.item_serial} />
                        </>)
                    }
                    
                     /*
                    if(rs.item_serial.length){
                        //tampilkan memo imei jika ada
                        return(<>
                        <tr>
                            <td colSpan={2}>
                            <Box sx={{ flexGrow: 1 }}>
                            <Grid  container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                {
                                    rs.item_serial.map((imei,key)=> {
                                        return(<>
                                            <Grid item xs={2} sm={4} md={4} key={key}>
                                                <Paper elevation={0} align={ArrAlign[key % 3]}>
                                                    <Typography sx={{paddingX: 1}} variant="body2" component="td">{imei}</Typography>
                                                </Paper>
                                            </Grid>
                                        </>);
                                    })
                                }
                            </Grid>
                            </Box>
                            </td>
                        </tr>
                        </>);
                    }
                    */
                })}

                <tr key='211'><td className='pindahan' colSpan={2}>&nbsp;</td></tr>
                    <tr key='212'>
                        <td><Typography sx={{paddingX: 1}} variant="button" component="td">Total</Typography></td>
                        <td align='right'><Typography sx={{paddingX: 1,alignItems: 'right'}} variant="button" component="td">{header.grand_total}&nbsp;</Typography></td>
                    </tr>
                    {
                        //Tampilkan total potongan jika ada
                        header.strpotongan.length ? (
                            <>
                                <tr key='213'>
                                    <td><Typography sx={{paddingX: 1}} variant="button" component="td">{header.strpotongan}</Typography></td>
                                    <td align='right' className="potongan"><Typography sx={{paddingX: 1,alignItems: 'right'}} variant="button" component="td">({header.potongan})</Typography></td>
                                </tr>
                            </>
                        )  : (
                            <>
                            </>
                        )
                    }
                    <tr key='214'>
                        <td><Typography sx={{paddingX: 1}} variant="button" component="td">{header.strbayar}</Typography></td>
                        <td align='right' className='pindahan'><Typography sx={{paddingX: 1,alignItems: 'right'}} variant="button" component="td">{header.dp}&nbsp;</Typography></td>
                    </tr>
                    <tr key='215'>
                        <td><Typography sx={{paddingX: 1}} variant="h6" component="td">{header.strtotal}</Typography></td>
                        <td align='right'><Typography sx={{paddingX: 1,alignItems: 'right'}} variant="h6" component="td">{header.netto}&nbsp;</Typography></td>
                    </tr>
            </tbody>
        </table>
    </Box>
    <Divider sx={{ paddingY: 2 }}>
        <Typography sx={{ paddingX: 1 }} variant="button" component="p">Transfer Via Bank</Typography>
        
    </Divider>
    <Box
    sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        paddingX: 1,
    }}
>
    <div key='2334'>
        BRI No.0004-0100-2451-306 
        <Button onClick={handleCopyToClipboardBRI} startIcon={<FileCopy />}></Button>
    </div>
    <div key='2335'>
        BCA No.357-124-4444  
        <Button onClick={handleCopyToClipboardBCA} startIcon={<FileCopy />}></Button>
    </div>
    <div key='2336'>
        A/N. CV Surya Pratama Ritelindo.
    </div>
</Box>
   {header.xpdc_status >=0 ? (<XpdcStatus status={header.xpdc_status} awb_id={header.awb_id} /> ):(<></>)} 
    {
        header.url_session.length > 10 ? (
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            paddingX: 1
        }}>
                     
            <Button sx={{paddingY: 0.5}} component={Link} onClick={goToTop} to={`/?sess_id=${header.url_session}`} size="small" variant="outlined" startIcon={<Reply />}>
            <Typography  variant='button' sx={{paddingX: 0.5}}>
               Kembali
            </Typography>
            </Button>

        </Box>):(<></>)
    } 

    </>
}

export default NotaDetail;