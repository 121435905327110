import React, { useEffect, useState } from "react";
import axios from "axios";
import TransaksiPapers from "./TransaksiPapers"; 
import Loader from "./Loader"; 
import Loaded from "./Loaded";
import InfiniteScroll from "react-infinite-scroll-component";
const PAGE_NUMBER = 1;
const API_URL = 'https://enota.suryamotor.co.id/apicall/';

const RiwayatScroll = ({mode,id}) =>{
    const [coinsData, setCoinsData] = useState([]);
    const [page, setPage] = useState(PAGE_NUMBER);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.post(
                    API_URL,
                    {
                        sess_id: id,
                        code: "0FAE873BD",
                        page: page, 
                        mode: mode
                    },
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }   
                    }
                );
                setLoading(false);
                setHasMore(response.data.more);
                if(response.data.res === false){
                    window.location.href="https://www.selismolis.com/";
                    return; 
                }
                if(response.data.records.length === 0  || response.data.more === false) {
                    setHasMore(false);
                }     
                setCoinsData((prev) => [...prev, ...response.data.records]);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [page, id, mode]);

    const fetchMoreData = () => {
        if(hasMore && !loading){
            setLoading(true);
            setPage((prev) => prev + 1);
        }
    };

    return (
        <>
            <InfiniteScroll
                dataLength={coinsData.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<Loader />}
                endMessage={<Loaded />}
            >
                <TransaksiPapers data={coinsData} />
            </InfiniteScroll>
        </>
    );
};

export default RiwayatScroll;