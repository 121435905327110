import React from "react";
import Typography from '@mui/material/Typography';
import {HdrStrong} from '@mui/icons-material';
import Box from '@mui/material/Box';

const NotaDetailItem = ({k,merk_nama,merk_tipe,item_nama,item_unit,item_harga,item_total})=> {
    return(<>
        <tr key={k+322}>
           <td colSpan={2} valign='top'>
           <Box sx={{
                   display: 'flex',
                   alignItems: 'center',
                   flexWrap: 'wrap',
                   verticalAlign: 'top'
               }}><HdrStrong className="iconTop" sx={{paddingY: 0}} />
               
               <Typography sx={{paddingX: 1, width: '90%'}} variant="body2" component="p">{merk_nama} {merk_tipe}<br />{item_nama}</Typography>
               </Box>
               </td>
       </tr>
       
       <tr key={k+2111}>
           <td align='left'><Typography sx={{paddingX: 4}} variant="body2" component="p">{item_unit} x {item_harga}</Typography></td>
           <td align='right'><Typography sx={{paddingX: 1}} variant="body2" component="p">{item_total}&nbsp;</Typography></td>
       </tr>  

   </>)
}

export default NotaDetailItem;