import React from "react";
import Typography from '@mui/material/Typography';
const NotaDetailGaransi = ({item_garansi})=> {
    if(item_garansi !== null){
        // tampilkan garansi jika ada
        return(<>
            <tr key='34325'>
                <td colSpan={2}>
                <Typography sx={{paddingX: 4}} variant="body2" component="td">Garansi sd tgl: {item_garansi}</Typography>
            </td>
            </tr>
        </>);
    }else{
        return <></>
    }
}

export default NotaDetailGaransi;