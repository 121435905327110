import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import Container from '@mui/material/Container';
import PelangganDetails from './components/PelangganDetails';
//import TransaksiTabs from './components/TransaksiTabs';
import { ToastContainer } from 'react-toastify';
import Nota from './components/Enota';
import Mutasi from './components/Emutasi';
import Etrack from './components/Etrack';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import Erating from './components/Erating';
function App() {
  return (
    
    <div className="App">
    <Container maxWidth="sm"
    sx={{p:1}}
    >
      <ToastContainer />
      <BrowserRouter>
        <ScrollToTop />
          <Routes>
          <Route path="/*" element={[<PelangganDetails />]} />
          <Route path="ereciept/*" element={<Nota />} />
          <Route path="emutasi/*" element={<Mutasi />} />
          <Route path="etrack/*" element={<Etrack />} />
          <Route path="erating/*" element={<Erating />} />
       </Routes>
        </BrowserRouter>
    </Container>
    
  </div>
  
  );
}

export default App;
