import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDowlaodIcon from '@mui/icons-material/CloudDownload';

const MutasiDetail=({data}) => {
    return (
        <>
        {
            data.map((rs,k)=> {
                return <Paper elevation={6} sx={{paddingY: 1.7, paddingX: 1}} variant='elevation' key={k+1}>
                <Box border={1} p={1} borderRadius="10px" sx={{ borderColor: rs.mode !== 1 ? 'green' : 'orange' }}>
                    <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">{rs.date}</Typography>
                            <Typography>
                                {rs.mode === 1 ? (
                                    <CloudUploadIcon color="error" />
                                ) : (
                                    <CloudDowlaodIcon color="success" />
                                )}
                            </Typography>
                        </Stack>
                        <Typography variant="body2">{rs.note}</Typography>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">{rs.total}</Typography>
                            <Typography variant="body1">{rs.currbalance}</Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Paper>
            })
        }
        </>
    );
}

export default MutasiDetail;