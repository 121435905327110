import React from "react";
import Typography from '@mui/material/Typography';
import {AssignmentReturned} from '@mui/icons-material';
import Box from '@mui/material/Box';

const NotaDetailJudul = ({k,judul})=> {
    return <>
            <Box key={k} sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100%'
            }} className="pindahan" width='100%'><AssignmentReturned />
                <Typography sx={{paddingX: 1}} variant="body2" component="p">{judul}</Typography>
            </Box>
    </>
}

export default NotaDetailJudul;