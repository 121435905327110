import React from "react";
import Box from '@mui/material/Box';
import  Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Divider from '@mui/material/Divider';
import {LocalShipping, HomeWork, RocketLaunch, Handshake} from '@mui/icons-material';
import {Link} from "react-router-dom";
import Button from '@mui/material/Button';


const XpdcStatus = ({status, awb_id})=> {
    let icon;
        switch (status) {
            case 0:
                icon = <HomeWork fontSize="large" />;
                break;
            case 1:
                icon = <LocalShipping fontSize="large" />;
                break;
            case 2:
                icon = <RocketLaunch fontSize="large" />;
                break;
            case 3:
            case 4:
                icon = <Handshake fontSize="large" />;
                break;
            default:
                icon = null;
                break;
        }
    return <><Divider sx={{ paddingY: 2 }}>
        <Typography sx={{ paddingX: 1 }} variant="button" component="p">Status Pengiriman</Typography>

    </Divider><Box
        sx={{
            display: "flex",
            alignItems: "center",
            paddingX: 1,
        }}
    >
            {awb_id ? (<>
                <Button sx={{paddingY: 0.5}} component={Link}  to={`/etrack?awb_id=${awb_id}`} size="small" variant="outlined" startIcon={(icon)}>
            <Typography  variant='button' sx={{paddingX: 0.5}}>
               Lacak
            </Typography>
            </Button>
            </>): (icon)}
          
        </Box><Box
            sx={{
                display: "flex",
                alignItems: "center",
                paddingX: 1, 
                paddingY: 3
            }}
        >
            <Stepper activeStep={status} alternativeLabel>
                <Step key={0}>
                    <StepLabel>
                        <HomeWork /> Persiapan Gudang
                    </StepLabel>
                </Step>
                <Step key={1}>
                    <StepLabel>
                        <LocalShipping /> Diterima Kurir
                    </StepLabel>
                </Step>
                <Step key={2}>
                    <StepLabel>
                        <RocketLaunch /> Pengiriman
                    </StepLabel>
                </Step>
                <Step key={3}>
                    <StepLabel>
                        <Handshake /> Diterima
                    </StepLabel>
                </Step>
            </Stepper>
        </Box>
        </>
        
}

export default XpdcStatus;